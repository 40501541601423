import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { MissedTarget } from '@economist/design-system/dist/umd/common';
import Link from '../components/link/link';
import Masthead from '../components/masthead/masthead';
import { DESIGN_SYSTEM_STYLES, routes } from '../constants';
import { ThemeProvider } from '../theme';

const StyledErrorPage = styled.div`
  margin: 0 0 3rem 0;
  padding: 0 var(--ds-grid-gap);
`;

const StyledErrorPageLayout = styled.div`
  padding-top: 2rem;
  ${({ theme }) => theme.mediaMinWidth.medium`
  padding-left: var(--ds-grid-gutter);
  padding-right: var(--ds-grid-gutter);
`}
  ${({ theme }) => theme.mediaMinWidth.large`
  padding-left: var(--ds-grid-gutter);
  padding-right: var(--ds-grid-gutter);
  padding-top: 6rem;
`}
`;

const StyledMissedTarget = styled(MissedTarget)`
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => theme.mediaMinWidth.medium`
  max-width: 75%;
`}
`;

const ErrorPage = () => {
  const { HOME } = routes;
  const headline = 'Our apologies. It looks like we couldn’t find this page.';
  const summary = (
    <>
      Go to the <Link href={HOME}>homepage</Link>.
    </>
  );

  return (
    <ThemeProvider>
      <Helmet>
        <link type='text/css' rel='stylesheet' href={DESIGN_SYSTEM_STYLES} />
      </Helmet>
      <Masthead />
      <StyledErrorPage>
        <StyledErrorPageLayout>
          <StyledMissedTarget headline={headline} summary={summary} />
        </StyledErrorPageLayout>
      </StyledErrorPage>
    </ThemeProvider>
  );
};

export default ErrorPage;
